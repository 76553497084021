import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Breadcrumb as BreadcrumbChakra,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { useDesignTokens } from '../../providers/design_tokens_provider';

const breadcrumbs = {
  doctors: 'Médicos',
  professionals: 'Profissionais',
  search: 'Consulta',
  contract: 'Contrato',
  elaborate: 'Elaboração',
  reviewer: 'Aprovadores',
  creation: 'Cadastrar',
  marketplace: 'Marketplace',
  'business-partner': 'Business Partner',
  'pre-approval': 'Pré-Aprovação',
  pf_professionals: 'Profissionais Pessoa Física',
};

export default function Breadcrumb() {
  const tokens = useDesignTokens();
  const path = usePathname();

  const breadcrumbItems = useMemo(() => {
    if (path) {
      const subPaths = path.split('/').filter((path) => path);
      const items = subPaths.map((path, index) => ({
        href: `/${subPaths.slice(0, index + 1).join('/')}`,
        title: breadcrumbs[path] || path,
      }));

      return [{ href: '/', title: 'Home' }, ...items];
    }

    return [];
  }, [path]);

  return (
    <BreadcrumbChakra
      spacing={2}
      separator={<ChevronRightIcon color='gray.500' />}
      marginBottom={4}
      color={tokens.text.primary.color}
    >
      {breadcrumbItems.map((item, index) => (
        <BreadcrumbItem
          key={item.href}
          isCurrentPage={breadcrumbItems.length - 1 === index}
          color={'blue.light'}
        >
          <BreadcrumbLink href={item.href}>{item.title}</BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </BreadcrumbChakra>
  );
}
