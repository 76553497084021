import { SearchIcon } from '@chakra-ui/icons';
import { type BoxProps, Button, Flex, Spinner, Text } from '@chakra-ui/react';
import {
  AuditOutlined,
  FileTextOutlined,
} from '@medsimples/design-system/src/antd-icons';
import type { FeatureFlags } from '@medsimples/doctor-onboarding-openapi-v2';
import Doctors from '@public/icons/doctors/doctors';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { PageRoutes } from '../../../routes';
import {
  type AuthProviderValue,
  useAuth,
} from '../../providers/auth_provider_client';
import { NavItem } from './navitem';

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

interface Link {
  name: string;
  path: string;
  permissions: string[];
  features?: Record<string, boolean | string>;
}

interface LinkItemProps extends Link {
  icon?: (color: string) => ReactNode;
  redirectTo?: string;
  subitems?: Link[];
}

const userPermissions = (auth: AuthProviderValue) =>
  Object.keys(auth.permissions);

const checkPermissions = (auth: AuthProviderValue, permissions?: string[]) =>
  permissions?.every((p) => userPermissions(auth).includes(p));

const checkFeatures = (
  flags: FeatureFlags,
  features?: Record<string, boolean | string>,
) => !features || Object.entries(features).every(([k, v]) => v === flags[k]);

export const Sidebar = ({ onClose, ...rest }: SidebarProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [enabledLinkItems, setEnabledLinkItems] = useState([]);
  const auth = useAuth();
  const flags = auth.featureFlags;

  const LinkItems: Array<LinkItemProps> = useMemo(
    () => [
      {
        path: '/professionals',
        ...PageRoutes['/professionals'],
        icon: (color: string) => <Doctors boxSize={5} color={color} />,
        subitems: flags.ACCREDITATION
          ? [
              {
                path: '/professionals',
                ...PageRoutes['/professionals'],
                name: 'Profissionais Prestadores',
              },
              {
                path: '/pf_professionals',
                ...PageRoutes['/pf_professionals'],
                name: 'Profissionais Pessoa Física',
              },
            ]
          : [],
      },

      {
        path: '/marketplace/professionals',
        ...PageRoutes['/marketplace/professionals'],
        icon: (color: string) => <AuditOutlined size={5} color={color} />,
        subitems: flags.ACCREDITATION
          ? [
              {
                path: '/marketplace/professionals',
                ...PageRoutes['/marketplace/professionals'],
                name: 'Profissionais Prestadores',
              },
              {
                path: '/marketplace/pf_professionals',
                ...PageRoutes['/marketplace/pf_professionals'],
                name: 'Profissionais Pessoa Física',
              },
            ]
          : [],
      },
      {
        path: '/search',
        ...PageRoutes['/search'],
        icon: (color: string) => <SearchIcon boxSize={5} color={color} />,
        subitems: [
          {
            path: '/search/business-partner',
            ...PageRoutes['/search/business-partner'],
          },
        ],
      },
      {
        path: '/pre-approval',
        ...PageRoutes['/pre-approval'],
        icon: (color: string) => <AuditOutlined size={5} color={color} />,
        subitems: flags.ACCREDITATION
          ? [
              {
                path: '/pre-approval/professionals',
                ...PageRoutes['/pre-approval/professionals'],
                name: 'Profissionais Prestadores',
              },
              {
                path: '/pre-approval/pf_professionals',
                ...PageRoutes['/pre-approval/pf_professionals'],
                name: 'Profissionais Pessoa Física',
              },
            ]
          : [],
      },
      {
        path: '/contract',
        ...PageRoutes['/contract'],
        icon: (color: string) => (
          <FileTextOutlined style={{ fontSize: '1.5em' }} color={color} />
        ),
        subitems: [
          {
            path: '/contract',
            ...PageRoutes['/contract'],
            name: 'Lista de contratos',
          },
          {
            path: '/contract/reviewer',
            ...PageRoutes['/contract/reviewer'],
          },
        ],
      },
    ],
    [flags],
  );

  useEffect(() => {
    if (auth?.user && flags) {
      setIsLoading(false);
      setEnabledLinkItems(
        LinkItems.reduce((enabled, curr) => {
          if (
            checkPermissions(auth, curr.permissions) &&
            checkFeatures(flags, curr.features)
          ) {
            enabled.push({
              ...curr,
              subitems: curr.subitems?.filter(
                (s) =>
                  checkPermissions(auth, s.permissions) &&
                  checkFeatures(flags, s.features),
              ),
            });
          }
          return enabled;
        }, [] as LinkItemProps[]),
      );
    }
  }, [auth, flags, LinkItems]);

  return (
    <Flex
      flexDirection={'column'}
      transition='3s ease'
      background='white'
      width='full'
      position='fixed'
      height='full'
      paddingY={5}
      {...rest}
    >
      {!isLoading ? (
        enabledLinkItems.map((link) => (
          <NavItem
            key={link.name}
            path={link.path}
            icon={(color: string) => link.icon(color)}
            subitems={link.subitems}
          >
            <Text fontSize='md' fontWeight={500} marginLeft={6}>
              {link.name}
            </Text>
          </NavItem>
        ))
      ) : (
        <Spinner />
      )}
      <Flex
        flexGrow={1}
        height={20}
        paddingX={4}
        alignItems='flex-end'
        justifyContent='flex-end'
      >
        <Button variant={'link'} onClick={onClose}>
          Fechar
        </Button>
      </Flex>
    </Flex>
  );
};
