'use client';

import { useQuery } from '@tanstack/react-query';
import { redirect, useRouter } from 'next/navigation';
import type { ReactNode } from 'react';
import { onboardingAPIV2Client, oryAPI } from '../../api';
import Loading from '../components/layout/loading';
import AuthProviderClient from './auth_provider_client';

const OryAuthProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const { isLoading, data } = useQuery({
    queryKey: ['orySession'],
    retry: 0,
    queryFn: () =>
      oryAPI.toSession().then(async ({ data: authCtx }) => {
        const logoutURL = (await oryAPI.createBrowserLogoutFlow()).data
          .logout_url;
        const name =
          authCtx?.identity?.traits?.raw_claims?.name &&
          authCtx?.identity?.traits?.raw_claims?.name.split(' ').length > 1
            ? `${authCtx?.identity?.traits?.raw_claims?.name
                .split(' ')
                .shift()} ${authCtx?.identity?.traits?.raw_claims?.name
                .split(' ')
                .pop()}`
            : authCtx?.identity?.traits?.raw_claims?.name;
        const res = await onboardingAPIV2Client.admin.meAdmin();
        if (!res.success) {
          router.push('/login');
          return;
        }
        const adminUser = res.data;
        const authContext = {
          isAuthenticated: authCtx.active,
          name,
          logoutURL,
        };
        return { adminUser, authContext };
      }),
  });

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && !data) {
    redirect('/login');
  }

  if (!isLoading && data) {
    return (
      <AuthProviderClient
        authContext={data?.authContext}
        adminUser={data?.adminUser}
      >
        {children}
      </AuthProviderClient>
    );
  }

  redirect('/login');
};

export default OryAuthProvider;
