export interface PageRoute {
  name: string;
  permissions: string[];
  features?: Record<string, boolean | string>;
}

export const PageRoutes = {
  '/doctors': {
    name: 'Médicos',
    permissions: ['admin.listProfessionals'],
  } as PageRoute,
  '/professionals': {
    name: 'Profissionais',
    permissions: ['admin.listProfessionals'],
    features: { ENABLE_PRE_APPROVAL: false },
  } as PageRoute,
  '/pre-approval': {
    name: 'Pré Aprovação',
    permissions: ['admin.preApprovalActions'],
    features: { ENABLE_PRE_APPROVAL: true },
  } as PageRoute,
  '/pre-approval/professionals': {
    name: 'Lista profissionais',
    permissions: ['admin.listPreApprovalProfessionals'],
    features: { ENABLE_PRE_APPROVAL: true },
  } as PageRoute,
  '/marketplace/professionals': {
    name: 'Pré-aprovados',
    permissions: ['admin.marketplaceProfessionalList'],
    features: { PROFESSIONAL_MARKETPLACE: true },
  } as PageRoute,
  '/search': {
    name: 'Consulta',
    permissions: ['admin.getBusinessPartnerBukrs'],
  } as PageRoute,
  '/search/business-partner': {
    name: 'Business Partner',
    permissions: ['admin.getBusinessPartnerBukrs'],
  } as PageRoute,
  '/contract': {
    name: 'Contratos',
    permissions: ['admin.listProfessionals', 'admin.listContracts'],
    features: { CONTRACT_MODULE: true },
  } as PageRoute,
  '/contract/reviewer': {
    name: 'Aprovadores',
    permissions: [
      'admin.listProfessionals',
      'admin.listContracts',
      'admin.listContractReviewer',
    ],
    features: { CONTRACT_MODULE: true, CONTRACT_REVIEWER: true },
  } as PageRoute,
  '/pf_professionals': {
    name: 'Profissionais Pessoa Física',
    permissions: ['admin.listProfessionals'],
    features: { ENABLE_PRE_APPROVAL: false, ACCREDITATION: true },
  } as PageRoute,
  '/pre-approval/pf_professionals': {
    name: 'Lista profissionais Pessoa Física',
    permissions: ['admin.listPreApprovalProfessionals'],
    features: { ENABLE_PRE_APPROVAL: true, ACCREDITATION: true },
  } as PageRoute,
  '/marketplace/pf_professionals': {
    name: 'Pré-aprovados Pessoa Física',
    permissions: ['admin.marketplaceProfessionalList'],
    features: { PROFESSIONAL_MARKETPLACE: true, ACCREDITATION: true },
  } as PageRoute,
};
