import { Flex, Spinner, Text } from '@chakra-ui/react';
import { Poppins } from 'next/font/google';

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
});

export default function Loading() {
  return (
    <Flex
      h='100%'
      w='100%'
      marginTop={'20%'}
      justifyContent='center'
      alignItems='center'
      flexWrap='nowrap'
      flexDir={'column'}
      fontFamily={poppins.style.fontFamily}
    >
      <Spinner size={'lg'} />
      <Text fontSize={'xl'} paddingTop={4}>
        Carregando...
      </Text>
    </Flex>
  );
}
