'use client';

import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { Poppins } from 'next/font/google';
import type { ReactNode } from 'react';
import Breadcrumb from '../components/layout/breadcrumb';
import Header from '../components/layout/header';
import { Sidebar } from '../components/layout/sidebar';
import { UserInfo } from '../components/layout/userinfo';
import { useDesignTokens } from '../providers/design_tokens_provider';

const poppins = Poppins({
  weight: ['300', '400', '500', '600', '700'],
  subsets: ['latin'],
});

interface props {
  children: ReactNode;
}

export default function TemplateClient({ children }: props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tokens = useDesignTokens();

  return (
    <Box minH='100vh' minW='100vw' bg='gray.100'>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='xs'
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <UserInfo onOpen={onOpen} />
      <Box
        padding={10}
        paddingTop={4}
        background={tokens.background.light.color}
        minHeight='calc(100vh - 80px)'
      >
        <Header />
        <Breadcrumb />
        {children}
      </Box>
    </Box>
  );
}
